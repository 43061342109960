<template>
  <div>
    <div class="level">
      <div class="level-left" />

      <div class="level-right">
        <div class="level-item">
          <b-button
            v-if="Object.keys(filters).length > 0"
            size="is-small"
            icon="power-off"
            @click="resetFilters"
          >
            Reset Filters
          </b-button>

          <b-dropdown
            aria-role="list"
            postition="is-bottom-left"
            :scrollable="true"
            :max-height="300"
            :close-on-click="false"
            class="dg-columns"
          >
            <b-button
              slot="trigger"
              icon-left="eye"
              size="is-small"
              icon-right="chevron-down"
            >
              columns
            </b-button>

            <draggable
              v-model="columns"
              group="columns"
              handle=".handle"
              @start="drag = true"
              @end="drag = false"
            >
              <b-dropdown-item
                v-for="column in columns"
                :key="column.id"
                aria-role="listitem"
              >
                <b-field>
                  <b-checkbox
                    :id="column.field"
                    v-model="column.visible"
                    size="is-small"
                  >
                    {{ column.label }}
                  </b-checkbox>
                  <i class="grip-vertical handle" />
                </b-field>
              </b-dropdown-item>
            </draggable>
            <hr class="dropdown-divider">
          </b-dropdown>

          <b-dropdown
            aria-role="list"
            postition="is-bottom-left"
          >
            <b-button
              slot="trigger"
              size="is-small"
              icon-left="wrench"
              icon-right="chevron-down"
            >
              grids
            </b-button>

            <b-dropdown-item aria-role="listitem">
              <b-field>
                <b-checkbox
                  v-model="customizations.isBordered"
                  size="is-small"
                >
                  Bordered
                </b-checkbox>
              </b-field>
            </b-dropdown-item>
            <hr class="dropdown-divider">

            <b-dropdown-item aria-role="listitem">
              <b-field>
                <b-checkbox
                  v-model="customizations.isCheckable"
                  size="is-small"
                >
                  Checkable
                </b-checkbox>
              </b-field>
            </b-dropdown-item>
            <hr class="dropdown-divider">

            <b-dropdown-item aria-role="listitem">
              <b-field>
                <b-checkbox
                  v-model="customizations.isNarrowed"
                  size="is-small"
                >
                  Narrowed
                </b-checkbox>
              </b-field>
            </b-dropdown-item>
            <hr class="dropdown-divider">

            <b-dropdown-item aria-role="listitem">
              <b-field>
                <b-checkbox
                  v-model="customizations.isStickyHeaders"
                  size="is-small"
                >
                  Sticky headers
                </b-checkbox>
              </b-field>
            </b-dropdown-item>
            <hr class="dropdown-divider">

            <b-dropdown-item aria-role="listitem">
              <b-field>
                <b-checkbox
                  v-model="customizations.isScrollable"
                  size="is-small"
                >
                  Scrollable
                </b-checkbox>
              </b-field>
            </b-dropdown-item>

            <hr class="dropdown-divider">

            <b-dropdown-item aria-role="listitem">
              <b-field>
                <b-checkbox
                  v-model="customizations.isStriped"
                  size="is-small"
                >
                  Striped
                </b-checkbox>
              </b-field>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>

    <b-table
      ref="userGoalsTable"
      :mobile-cards="false"
      :loading="loading"
      :data="usersGoalsData.content"
      :checkable="customizations.isCheckable"
      :checked-rows.sync="checkedRows"
      paginated
      backend-pagination
      :total="total"
      :per-page="perPage"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      detailed
      detail-key="id"
      :opened-detailed="defaultOpenedDetails"
      custom-detail-row
      :narrowed="customizations.isNarrowed"
      :bordered="customizations.isBordered"
      :striped="customizations.isStriped"
      :sticky-header="customizations.isStickyHeaders"
      :scrollable="customizations.isScrollable"
      backend-filtering
      :debounce-search="800"
      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      :sort-icon="sortIcon"
      :sort-icon-size="sortIconSize"
      @sort="onSort"
      @filters-change="onFilter"
      @page-change="onPageChange"
      @details-open="(row) => fetchGoalPeriods(row.id)"
    >
      <b-table-column
        v-for="(column, index) in columns"
        :key="index"
        :label="column.label"
        :field="column.field"
        :searchable="column.searchable"
        :sortable="column.sortable"
        :visible="column.visible"
      >
        <template
          slot="searchable"
          slot-scope="props"
        >
          <template>
            <b-input
              v-if="
                column.searchField === 'fullName' ||
                  column.searchField === 'salesId' ||
                  column.searchField === 'goalType' ||
                  column.searchField === 'linkedSalesIds' ||
                  column.searchField === 'period' ||
                  column.searchField === 'sales' ||
                  // column.searchField === 'actualSales' ||
                  column.searchField === 'salesGp' ||
                  // column.searchField === 'actualSalesGp' ||
                  column.searchField === 'booking' ||
                  // column.searchField === 'actualBooking' ||
                  column.searchField === 'bookingGp' ||
                  // column.searchField === 'actualBookingGp' ||
                  column.searchField === 'year'
              "
              v-model="props.filters[`${column.searchField}`]"
              placeholder="Search..."
              icon="search"
              size="is-small"
            />
          </template>
        </template>

        <template v-slot="props">
          <template
            v-if="column.field === 'fullName'"
          >
            <template>
              <span
                v-if="props.row[column.field]"
                class="is-pointer"
              >
                {{ props.row[column.field] }}
              </span>
            </template>
          </template>

          <template
            v-else-if="column.field === 'year'"
          >
            <template>
              <span
                v-if="props.row[column.field]"
                class="is-pointer"
              >
                {{ props.row[column.field] }}
              </span>
            </template>
          </template>

          <template
            v-else-if="column.field === 'goalType'"
          >
            <template>
              <span
                v-if="props.row[column.field]"
                class="is-pointer"
              >
                {{ props.row[column.field] }}
              </span>
            </template>
          </template>

          <template
            v-else-if="column.field === 'sales'"
          >
            <template>
              <span
                class="is-pointer"
              >
                {{ props.row[column.field] | currency }}
              </span>
            </template>
          </template>

          <template
            v-else-if="column.field === 'salesGp'"
          >
            <template>
              <span
                v-if="props.row[column.field]"
                class="is-pointer"
              >
                {{ props.row[column.field]+ '%' }}
              </span>
            </template>
          </template>

          <template
            v-else-if="column.field === 'booking'"
          >
            <template>
              <span
                class="is-pointer"
              >
                {{ props.row[column.field] | currency }}
              </span>
            </template>
          </template>

          <template
            v-else-if="column.field === 'goalPeriod'"
          >
            Annual
          </template>

          <template
            v-else-if="column.field === 'bookingGp'"
          >
            <template>
              <span
                v-if="props.row[column.field]"
                class="is-pointer"
              >
                {{ props.row[column.field]+ '%' }}
              </span>
            </template>
          </template>
          <template v-else>
            {{ props.row[column.field] }}
          </template>
        </template>
      </b-table-column>

      <template slot="footer">
        <table-footer
          :first-item="firstItem"
          :page="page"
          :per-page="perPage"
          :total="total"
        >
          <template #page-dropdown>
            <b-dropdown
              v-model="perPage"
              aria-role="list"
              position="is-top-left"
            >
              <b-button
                slot="trigger"
                type="is-info"
                size="is-small"
                icon-right="chevron-down"
              >
                {{ perPage }}
              </b-button>

              <template v-for="(pageCount, index) in pages">
                <b-dropdown-item
                  :key="index"
                  :value="pageCount"
                >
                  {{ pageCount }}
                </b-dropdown-item>
                <hr
                  :key="pageCount"
                  class="dropdown-divider"
                >
              </template>
            </b-dropdown>
          </template>
        </table-footer>
      </template>

      <template slot="detail">
        <tr
          v-for="(item, index) in goalPeriods"
          :key="index"
        >
          <td />

          <td
            v-for="(column, columnIndex) in columns"
            :key="columnIndex"
          >
            <template
              v-if="
                column.field === 'actualSales' ||
                  column.field === 'actualBookings' ||
                  column.field === 'actualSalesGp' ||
                  column.field === 'actualBookingsGp' ||
                  column.field === 'sales' ||
                  column.field === 'booking'
              "
            >
              {{ goalPeriods[index][column.field] | currency }}
            </template>

            <template v-else-if="column.field === 'salesGp' || column.field === 'bookingGp'">
              {{ goalPeriods[index][column.field] }}
            </template>

            <template v-else-if="column.field === 'linkedSalesIds'">
              {{ goalPeriods[index]['salesPersonId'] }}
            </template>

            <template v-else>
              {{ goalPeriods[index][column.field] }}
            </template>
          </td>
        </tr>
      </template>

      <template slot="empty">
        <empty-table table-name="lookups" />
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';

import { EmptyTable, TableFooter } from '@/components/Shared';
import SearchMixin from '@/mixins/SearchMixin';

export default {
  name: 'UserGoalsTable',

  components: {
    TableFooter,
    EmptyTable,
    draggable,
  },

  filters: {
    /**
     * Truncate text that is more than 100 characters in length
     *
     * @param {String} text text to truncate
     * @returns truncated text
     */
    truncateText(text) {
      const charactersLength = 100;
      const textEnding = '...';
      if (text.length <= charactersLength) {
        return text;
      }
      return text.slice(0, charactersLength - textEnding.length) + textEnding;
    },
  },

  mixins: [SearchMixin],

  data: () => ({
    checkedRows: [],

    total: 0,
    page: 1,
    perPage: 25,
    pages: [10, 25, 50, 100, 250, 500, 1000, 2000],

    defaultOpenedDetails: [],

    sortField: 'updatedAt',
    sortOrder: 'desc',
    defaultSortOrder: 'desc',
    sortIcon: 'menu-up',
    sortIconSize: 'is-small',

    selectedUserGoalId: '',

    filters: {},

    exportFields: {},

    searchClause: '',

    columns: [
      {
        id: '1',
        label: 'User Name',
        field: 'fullName',
        sortable: true,
        searchable: true,
        searchField: 'fullName',
        exportField: 'fullName',
        sticky: false,
        visible: true,
      },
      {
        id: '2',
        label: 'Sales ID',
        field: 'linkedSalesIds',
        sortable: true,
        searchable: true,
        searchField: 'linkedSalesIds',
        exportField: 'linkedSalesIds',
        sticky: false,
        visible: true,
      },
      {
        id: '3',
        label: 'Year',
        field: 'year',
        sortable: true,
        searchable: true,
        searchField: 'year',
        exportField: 'year',
        sticky: false,
        visible: true,
      },
      {
        id: '4',
        label: 'Goal Type',
        field: 'goalType',
        sortable: true,
        searchable: true,
        searchField: 'goalType',
        exportField: 'goalType',
        sticky: false,
        visible: true,
      },
      {
        id: '5',
        label: 'Period',
        field: 'goalPeriod',
        sortable: true,
        searchable: true,
        searchField: 'period',
        exportField: 'period',
        sticky: false,
        visible: true,
      },
      {
        id: '5',
        label: 'Sales $',
        field: 'sales',
        sortable: true,
        searchable: true,
        searchField: 'sales',
        exportField: 'sales',
        sticky: false,
        visible: true,
      },
      {
        id: '6',
        label: 'Actual Sales $',
        field: 'actualSales',
        sortable: true,
        searchable: true,
        searchField: 'actualSales',
        exportField: 'actualSales',
        sticky: false,
        visible: true,
      },
      {
        id: '7',
        label: 'Sales GP%',
        field: 'salesGp',
        sortable: true,
        searchable: true,
        searchField: 'salesGp',
        exportField: 'salesGp',
        sticky: false,
        visible: true,
      },
      {
        id: '8',
        label: 'Actual Sales GP%',
        field: 'actualSalesGpPercentage',
        sortable: true,
        searchable: true,
        searchField: 'actualSalesGp',
        exportField: 'actualSalesGp',
        sticky: false,
        visible: true,
      },
      {
        id: '9',
        label: 'Booking $',
        field: 'booking',
        sortable: true,
        searchable: true,
        searchField: 'booking',
        exportField: 'booking',
        sticky: false,
        visible: true,
      },
      {
        id: '10',
        label: 'Actual Booking $',
        field: 'actualBookings',
        sortable: true,
        searchable: true,
        searchField: 'actualBooking',
        exportField: 'actualBooking',
        sticky: false,
        visible: true,
      },
      {
        id: '11',
        label: 'Booking GP%',
        field: 'bookingGp',
        sortable: true,
        searchable: true,
        searchField: 'bookingGp',
        exportField: 'bookingGp',
        sticky: false,
        visible: true,
      },
      {
        id: '12',
        label: 'Actual Booking GP%',
        field: 'actualBookingsGpPercentage',
        sortable: true,
        searchable: true,
        searchField: 'actualBookingGp',
        exportField: 'actualBookingGp',
        sticky: false,
        visible: true,
      },
    ],

    customizations: {
      isBordered: false,
      isCheckable: false,
      isNarrowed: false,
      isStickyHeaders: false,
      isScrollable: true,
      isStriped: true,
    },

  }),

  computed: {
    ...mapGetters({
      grid: 'Grids/getUserGoalsGrid',
      loading: 'DataGrids/getLoading',
      usersGoalsData: 'DataGrids/getUserGoals',
      goalPeriods: 'Settings/getGoalPeriods',
    }),

    firstItem() {
      const firstItem = this.page * this.perPage - this.perPage + 1;
      return firstItem >= 0 ? firstItem : 0;
    },
  },

  watch: {
    perPage: {
      handler() {
        this.fetchUsersGoals();
      },
      deep: true,
    },

    columns: {
      handler(value) {
        this.generateExportFields(value);
      },

      deep: true,
      immediate: true,
    },
  },

  async created() {
    try {
      const organizationId = localStorage.getItem('organization_id');
      const response = await this.$store.dispatch('Grids/fetchUserGoalsGrid', {
        gridName: 'userGoals',
        organizationId,
      });
      // await this.$store.dispatch('DataGrids/fetchUsersGoals');

      if (response) {
        const isConfigurationValid = this.configurationValidator();
        if (isConfigurationValid) {
          this.setGrid();
        }
      } else {
        await this.$store.dispatch('Grids/createGrid', {
          organizationid: localStorage.getItem('organization_id'),
          userid: localStorage.getItem('user_id'),
          columns: this.columns,
          name: 'userGoals',
          customizations: this.customizations,
          filters: this.filters,
        });
      }
      await this.mapSavedFilters();
    } catch (error) {
      console.error(error);
    }
  },

  mounted() {
    if (Object.keys(this.$route.query).length > 0) {
      this.filters = { ...this.$route.query };
    }
    this.fetchUsersGoals();
    // this.$store.dispatch('Lookups/fetchActivityTypes');
    // this.$store.dispatch('Lookups/fetchTaskPriorities');
    // this.$store.dispatch('Lookups/fetchTaskTypes');
  },

  destroyed() {
    if (localStorage.getItem('access_token')) {
      this.$store.dispatch('Grids/updateGrid', {
        organizationid: localStorage.getItem('organization_id'),
        userid: localStorage.getItem('user_id'),
        columns: this.columns,
        name: 'userGoals',
        customizations: this.customizations,
        filters: this.filters,
      });
    }
    this.$store.commit('DataGrids/CLEAR_USER_GOALS');
  },

  methods: {
    mapSavedFilters() {
      if (this.grid.filters) {
        this.$refs.userGoalsTable.filters = { ...this.grid.filters };
      }
      return '';
    },

    generateExportFields(data) {
      const result = data
        .filter((el) => el.visible && el.exportField)
        .map((el) => [el.label, el.exportField]);
      this.exportFields = Object.fromEntries(new Map(result));
    },

    configurationValidator() {
      const defaultConfiguration = this.columns.map((el) => el.label).sort();
      const userConfiguration = this.grid.columns.map((el) => el.label).sort();
      const result = defaultConfiguration.every(
        (configuration, index) => configuration === userConfiguration[index],
      );
      return result;
    },

    setGrid() {
      this.columns.sort(
        (a, b) => this.grid.columns.findIndex((el) => el.label === a.label)
          - this.grid.columns.findIndex((el) => el.label === b.label),
      );
      const newColumns = this.columns.map((el, index) => ({
        ...el,
        visible: this.grid.columns[index].visible,
      }));
      this.columns = newColumns;
      this.customizations = this.grid.customizations;
    },

    /**
     * fetchUsersGoals
     *
     */
    async fetchUsersGoals() {
      let filters;
      if (typeof this.filters !== 'object') {
        filters = '';
      } else {
        filters = { ...this.filters };
        Object.keys(filters).forEach((key) => filters[key] === '' && delete filters[key]);
        if (Object.keys(filters).includes('createdAt')) {
          filters.createdAt = new Date(filters.createdAt).setDate(
            new Date(filters.createdAt).getDate(),
          );
          filters.createdAt = new Date(filters.createdAt).toISOString().substring(0, 10);
        }
        if (Object.keys(filters).includes('updatedAt')) {
          filters.updatedAt = new Date(filters.updatedAt).setDate(
            new Date(filters.updatedAt).getDate(),
          );
          filters.updatedAt = new Date(filters.updatedAt).toISOString().substring(0, 10);
        }
      }

      try {
        await this.$store.dispatch('DataGrids/fetchUserGoals', {
          page: this.page - 1,
          size: this.perPage,
          sort: [this.sortField, this.sortOrder],
          filters: filters && Object.keys(filters).length > 0
            ? `&${new URLSearchParams(filters).toString()}` : '',
          // userId: this.userId,
        });
        this.total = this.usersGoalsData.totalElements;
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * onPageChange
     *
     * handle page change events
     * @param {Number} page
     */
    onPageChange(page) {
      this.page = page;
      this.from += this.perPage;
      this.fetchUsersGoals();
    },

    /**
     * onSort
     *
     * @param {String} field
     * @param {String} order
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.fetchUsersGoals();
    },

    /**
     * onFilter
     *
     * @param {object} filters
     */
    onFilter(filters) {
      this.filters = { ...this.filters, ...filters };
      this.fetchUsersGoals();
    },

    /**
     * fetchGoalPeriods
     *
     * @param {Number} value - Goal ID
     */
    fetchGoalPeriods(value) {
      this.defaultOpenedDetails = [value];
      this.$store.dispatch('Settings/fetchGoalPeriods', value);
    },

    /**
     * resetFilters
     *
     */
    resetFilters() {
      this.$refs.userGoalsTable.resetMultiSorting();
      this.$refs.userGoalsTable.filters = {};
      this.filters = {};
      this.fetchUsersGoals();
    },
  },
};
</script>

<style lang="css" scoped>
</style>
