<template>
  <div>
    <table-header
      :is-drilldown="false"
      :fields="exportFields"
      :data="users"
      @search="searchUser"
    >
      <template #update-button>
        <b-button
          v-if="isEditing && selectedUserId"
          ref="saveEntryButton"
          size="is-small"
          class="mr-4"
          icon-left="content-save"
          type="is-success"
          @click="updateUser"
        >
          save
        </b-button>
        <b-button
          v-if="isEditing && selectedUserId"
          ref="cancelEntryButton"
          size="is-small"
          icon-left="close"
          type="is-success"
          @click="cancelUpdate"
        >
          cancel
        </b-button>
      </template>

      <template #open-switch-button>
        <b-field>
          <b-switch v-model="openSwitch">
            <small class="has-text-weight-bold">
              {{ openSwitch ? 'Show Active' : 'Show All' }}
            </small>
          </b-switch>
        </b-field>
      </template>

      <template #reset-button>
        <b-button
          v-if="Object.keys(filters).length > 0 || searchClause"
          size="is-small"
          icon-left="power-off"
          @click="resetFilters"
        >
          Reset Filters
        </b-button>
      </template>

      <template #customize>
        <b-dropdown
          aria-role="list"
          postition="is-bottom-left"
        >
          <b-button
            slot="trigger"
            size="is-small"
            icon-left="wrench"
            icon-right="chevron-down"
          >
            grids
          </b-button>

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isBordered"
                size="is-small"
              >
                Bordered
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isCheckable"
                size="is-small"
              >
                Checkable
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isNarrowed"
                size="is-small"
              >
                Narrowed
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.stickyHeaders"
                size="is-small"
              >
                Sticky headers
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isScrollable"
                size="is-small"
              >
                Scrollable
              </b-checkbox>
            </b-field>
          </b-dropdown-item>

          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isStriped"
                size="is-small"
              >
                Striped
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
        </b-dropdown>
      </template>

      <template #hide-show-columns>
        <b-dropdown
          aria-role="list"
          postition="is-bottom-left"
          :scrollable="true"
          :max-height="300"
          :close-on-click="false"
        >
          <b-button
            slot="trigger"
            icon-left="eye"
            size="is-small"
            icon-right="chevron-down"
          >
            columns
          </b-button>

          <draggable
            v-model="columns"
            group="columns"
            @start="drag=true"
            @end="drag=false"
          >
            <b-dropdown-item
              v-for="column in columns"
              :key="column.id"
              aria-role="listitem"
            >
              <b-field>
                <b-checkbox
                  :id="column.field"
                  v-model="column.visible"
                  size="is-small"
                >
                  {{ column.label }}
                </b-checkbox>
              </b-field>
            </b-dropdown-item>
          </draggable>
          <hr class="dropdown-divider">
        </b-dropdown>
      </template>
    </table-header>

    <b-table
      ref="usersTable"
      v-click-outside-dg="onClickOutsideTable"
      :mobile-cards="false"
      :loading="isLoading"
      :data="users.content"
      :checkable="customizations.isCheckable"
      :checked-rows.sync="checkedRows"
      paginated
      backend-pagination
      :total="total"
      :per-page="perPage"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      :narrowed="customizations.isNarrowed"
      :bordered="customizations.isBordered"
      :striped="customizations.isStriped"
      :sticky-header="customizations.isStickyHeaders"
      :scrollable="customizations.isScrollable"
      backend-filtering
      :debounce-search="800"
      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      :sort-icon="sortIcon"
      :sort-icon-size="sortIconSize"
      @sort="onSort"
      @filters-change="onFilter"
      @page-change="onPageChange"
      @update:selected="onClickOutsideEditing"
    >
      <template v-for="(column, index) in columns">
        <b-table-column
          :key="index"
          :label="column.label"
          :field="column.field"
          :searchable="column.searchable"
          :sortable="column.sortable"
          :visible="column.visible"
        >
          <template
            slot="searchable"
            slot-scope="props"
          >
            <b-input
              v-model="props.filters[`${column.searchField}`]"
              placeholder="Search..."
              icon="search"
              size="is-small"
            />
          </template>

          <template v-slot="props">
            <template v-if="column.field === 'actions'">
              <div class="buttons">
                <b-tooltip
                  v-if="currentRole === 'ROLE_BUSINESS_ADMIN'
                    || currentRole === 'ROLE_SALES_MANAGER'"
                  type="is-info"
                  label="goals"
                  position="is-bottom"
                >
                  <b-button
                    type="is-text"
                    class="has-text-grey"
                    icon-right="dollar-sign"
                    tag="router-link"
                    :to="{ name: 'EditUser', params: { userId: props.row.id } }"
                  />
                </b-tooltip>

                <b-tooltip
                  v-if="currentRole === 'ROLE_BUSINESS_ADMIN'"
                  type="is-info"
                  label="Edit"
                  position="is-bottom"
                >
                  <b-button
                    type="is-text"
                    class="has-text-grey"
                    icon-right="pencil"
                    tag="router-link"
                    :to="{ name: 'EditUser', params: { userId: props.row.id } }"
                  />
                </b-tooltip>

                <b-tooltip
                  type="is-info"
                  label="Email"
                  position="is-bottom"
                >
                  <b-button
                    type="is-text"
                    class="has-text-grey"
                    icon-right="email"
                    @click="sendMail(
                      props.row.email, props.row.name
                    )"
                  />
                </b-tooltip>
              </div>
            </template>

            <template v-else-if="column.field === 'userPhoto'">
              <figure class="image is-32x32">
                <img
                  style="width:32px; height:32px;"
                  width="32"
                  height="32"
                  class="is-rounded"
                  :src="props.row.userPhoto ? props.row.userPhoto : defaultImage"
                  :alt="props.row.name"
                >
              </figure>
            </template>

            <template v-else-if="column.field === 'name'">
              <router-link
                class="has-text-primary has-text-weight-bold"
                :to="{ name: 'EditUser', params: { userId: props.row.id } }"
              >
                {{ props.row.name.trim() }}
              </router-link>
            </template>

            <b-tooltip
              v-else
              label="Double-click to edit"
              class="is-info"
            >
              <template v-if="column.field === 'email'">
                <b-field v-if="isEditing && props.row.id === selectedUserId">
                  <b-input
                    v-model="email"
                    size="is-small"
                    type="email"
                  />
                </b-field>

                <span
                  v-else
                  class="is-pointer"
                  @dblclick="onEdit(props.row.id)"
                >
                  {{ props.row[column.field] | lowercase }}
                </span>
              </template>

              <template v-else-if="column.field === 'phone'">
                <b-field v-if="isEditing && props.row.id === selectedUserId">
                  <vue-tel-input
                    v-model="phoneCell"
                    class="input is-small"
                  />
                </b-field>

                <span
                  v-else
                  class="is-pointer"
                  @dblclick="onEdit(props.row.id)"
                >
                  {{ props.row[column.field] }}
                </span>
              </template>

              <template v-else-if="column.field === 'isActive'">
                <b-field
                  v-if="isEditing && props.row.id === selectedUserId
                    && (hasRole('ROLE_BUSINESS_ADMIN'))"
                >
                  <b-switch
                    v-model="isActive"
                    size="is-small"
                    type="is-info"
                  />
                </b-field>

                <template v-else>
                  <span
                    class="is-pointer"
                    @dblclick="onEdit(props.row.id)"
                  >
                    {{ props.row.isActive }}
                  </span>
                </template>
              </template>

              <template v-else-if="column.field === 'teams'">
                <template v-if="props.row.teams">
                  <b-tag
                    v-for="(team, teamIndex) in props.row[column.field].split(',')"
                    :key="teamIndex"
                  >
                    {{ team }}
                  </b-tag>
                </template>
              </template>

              <template v-else-if="column.field === 'divisions'">
                <template v-if="props.row.divisions">
                  <b-tag
                    v-for="(division, divisionIndex) in props.row[column.field].split(',')"
                    :key="divisionIndex"
                  >
                    {{ division }}
                  </b-tag>
                </template>
              </template>

              <template v-else-if="column.field === 'managers'">
                <template v-if="props.row[column.field]">
                  {{ props.row[column.field] }}
                </template>
              </template>

              <template v-else-if="column.field === 'roles'">
                <b-tag v-if="props.row[column.field]">
                  {{ props.row[column.field] | role }}
                </b-tag>
              </template>

              <template v-else>
                {{ props.row[column.field] }}
              </template>
            </b-tooltip>
          </template>
        </b-table-column>
      </template>

      <template slot="footer">
        <table-footer
          :first-item="firstItem"
          :page="page"
          :per-page="perPage"
          :total="total"
        >
          <template #page-dropdown>
            <b-dropdown
              v-model="perPage"
              aria-role="list"
              position="is-top-left"
            >
              <b-button
                slot="trigger"
                size="is-small"
                type="is-info"
                icon-right="chevron-down"
              >
                {{ perPage }}
              </b-button>

              <template v-for="(pageCount, pageCountIndex) in pages">
                <b-dropdown-item
                  :key="pageCountIndex"
                  :value="pageCount"
                >
                  {{ pageCount }}
                </b-dropdown-item>
                <hr
                  :key="pageCountIndex"
                  class="dropdown-divider"
                >
              </template>
            </b-dropdown>
          </template>
        </table-footer>
      </template>

      <template slot="empty">
        <empty-table table-name="users" />
      </template>
    </b-table>

    <b-modal
      ref="inlineEditingSavePrompt"
      v-model="isSavePromptActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="inline editing save modal"
      aria-modal
    >
      <template #default="props">
        <inline-editing-save-prompt
          @save="updateUser"
          @close="props.close"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';

import {
  TableFooter, TableHeader, EmptyTable, InlineEditingSavePrompt,
} from '@/components/Shared';

import defaultImage from '@/assets/images/avatar.svg';

export default {
  name: 'UsersTable',

  components: {
    TableFooter,
    TableHeader,
    EmptyTable,
    draggable,
    InlineEditingSavePrompt,
  },

  filters: {
    role(value) {
      return value
        .replace(/_/g, ' ')
        .substring(5)
        .toLowerCase();
    },
  },

  data: () => ({
    checkedRows: [],
    defaultImage,

    total: 0,
    page: 1,
    perPage: 50,
    pages: [10, 25, 50, 100, 250, 500, 1000, 2000],

    sortField: 'name',
    sortOrder: 'desc',
    defaultSortOrder: 'desc',
    sortIcon: 'menu-up',
    sortIconSize: 'is-small',

    isSavePromptActive: false,

    savePromptOpen: false,

    filters: {},

    exportFields: {},

    currentRole: localStorage.getItem('role'),

    selectedUserId: '',
    isEditing: false,

    searchClause: '',

    openSwitch: true,

    columns: [
      {
        id: 0,
        label: 'Avatar',
        field: 'userPhoto',
        sortable: false,
        searchable: false,
        searchField: '',
        exportField: 'userPhoto',
        sticky: false,
        visible: true,
      }, {
        id: 1,
        label: 'User Name',
        field: 'name',
        sortable: true,
        searchable: true,
        searchField: 'name',
        exportField: 'name',
        sticky: false,
        visible: true,
      }, {
        label: 'Email',
        field: 'email',
        sortable: true,
        searchable: true,
        searchField: 'email',
        exportField: 'email',
        sticky: false,
        visible: true,
      }, {
        id: 2,
        label: 'Phone',
        field: 'phone',
        sortable: true,
        searchable: true,
        searchField: 'phone',
        exportField: 'phone',
        sticky: false,
        visible: false,
      }, {
        id: 3,
        label: 'Manager',
        field: 'managers',
        sortable: true,
        searchable: true,
        searchField: 'managers',
        exportField: 'managers',
        sticky: false,
        visible: true,
      }, {
        id: 4,
        label: 'Team',
        field: 'teams',
        sortable: true,
        searchable: true,
        searchField: 'teams',
        exportField: 'teams',
        sticky: false,
        visible: true,
      }, {
        id: 5,
        label: 'Division',
        field: 'divisions',
        sortable: true,
        searchable: true,
        searchField: 'divisions',
        exportField: 'divisions',
        sticky: false,
        visible: false,
      }, {
        id: 6,
        label: 'Role',
        field: 'roles',
        sortable: true,
        searchable: true,
        searchField: 'roles',
        exportField: 'roles',
        sticky: false,
        visible: false,
      }, {
        id: 7,
        label: 'Actions',
        field: 'actions',
        sortable: false,
        searchable: false,
        searchField: '',
        exportField: '',
        sticky: false,
        visible: true,
      },
      {
        id: 8,
        label: 'Active',
        field: 'isActive',
        sortable: true,
        searchable: false,
        searchField: 'isActive',
        exportField: 'isActive',
        sticky: false,
        visible: true,
      },
    ],

    customizations: {
      isBordered: false,
      isCheckable: false,
      isNarrowed: false,
      isStickyHeaders: false,
      isScrollable: true,
      isStriped: true,
    },

  }),

  computed: {
    body() {
      return this.checkedRows.length > 0 ? this.checkedRows : this.users;
    },

    firstItem() {
      const firstItem = this.page * this.perPage - this.perPage + 1;
      return firstItem >= 0 ? firstItem : 0;
    },

    email: {
      get() {
        return this.$store.state.Users.user.email;
      },
      set(value) {
        this.$store.commit('Users/SET_USER_USERNAME', value);
        return this.$store.commit('Users/SET_USER_EMAIL', value);
      },
    },

    isActive: {
      get() {
        return this.$store.state.Users.user.isActive;
      },

      set(value) {
        return this.$store.commit('Users/SET_USER_IS_ACTIVE', value);
      },
    },

    phoneCell: {
      get() {
        return this.$store.state.Users.user.phoneCell;
      },
      set(value) {
        return this.$store.commit('Users/SET_USER_PHONE_CELL', value);
      },
    },

    ...mapGetters({
      grid: 'Grids/getGrid',
      user: 'Users/getUser',
      users: 'DataGrids/getUsers',
      isLoading: 'Users/getLoading',
    }),
  },

  watch: {
    perPage: {
      handler() {
        this.fetchUsers();
      },
      deep: true,
    },

    openSwitch: {
      handler() {
        this.fetchUsers();
      },

      deep: true,
    },

    columns: {
      handler(value) {
        this.generateExportFields(value);
      },

      deep: true,
      immediate: true,
    },
  },

  async created() {
    try {
      const organizationId = localStorage.getItem('organization_id');
      const response = await this.$store.dispatch('Grids/fetchGrid', {
        gridName: 'users',
        organizationId,
      });
      if (response) {
        const isConfigurationValid = this.configurationValidator();
        if (isConfigurationValid) {
          this.setGrid();
        }
      } else {
        await this.$store.dispatch('Grids/createGrid', {
          organizationid: localStorage.getItem('organization_id'),
          userid: localStorage.getItem('user_id'),
          columns: this.columns,
          name: 'users',
          customizations: this.customizations,
        });
      }
    } catch (error) {
      console.error(error);
    }
  },

  mounted() {
    this.fetchUsers();
  },

  destroyed() {
    if (localStorage.getItem('access_token')) {
      this.$store.dispatch('Grids/updateGrid', {
        organizationid: localStorage.getItem('organization_id'),
        userid: localStorage.getItem('user_id'),
        columns: this.columns,
        name: 'users',
        customizations: this.customizations,
      });
    }
  },

  methods: {
    generateExportFields(data) {
      const result = data
        .filter((el) => el.visible && el.exportField)
        .map((el) => [el.label, el.exportField]);
      this.exportFields = Object.fromEntries(new Map(result));
    },

    configurationValidator() {
      const defaultConfiguration = this.columns.map((el) => el.label)
        .sort();
      const userConfiguration = this.grid.columns.map((el) => el.label)
        .sort();
      const result = defaultConfiguration.every(
        (configuration, index) => configuration === userConfiguration[index],
      );
      return result;
    },

    onClickOutsideEditing(row) {
      if (row.id && this.isEditing) {
        if (row.id !== this.selectedUserId) {
          this.savePromptOpen = true;
          this.isSavePromptActive = true;
        }
      }
    },

    onClickOutsideTable() {
      if (this.isEditing) {
        this.savePromptOpen = true;
        this.isSavePromptActive = true;
      }
    },

    /**
     * setGrid
     *
     */
    setGrid() {
      this.columns.sort(
        (a, b) => this.grid.columns.findIndex(
          (el) => el.label === a.label,
        ) - this.grid.columns.findIndex((el) => el.label === b.label),
      );
      const newColumns = this.columns.map(
        (el, index) => ({
          ...el,
          visible: this.grid.columns[index].visible,
        }),
      );
      this.columns = newColumns;
      this.customizations = this.grid.customizations;
    },

    /**
     * sendMail
     *
     */
    sendMail(email, subject) {
      window.location.assign(`mailto:${email}?subject=${subject}`);
    },

    /**
     * fetchUsers
     *
     * fetch all organization's users
     */
    async fetchUsers() {
      let filters;
      if (typeof this.filters !== 'object') {
        filters = '';
      } else {
        filters = { ...this.filters };
        Object.keys(filters)
          .forEach((key) => filters[key] === '' && delete filters[key]);
      }

      if (this.openSwitch) {
        // TODO---  change isActive
        filters = {
          ...filters,
          isActive: true,
        };
      }

      try {
        await this.$store.dispatch('DataGrids/fetchUsers', {
          page: this.page - 1,
          size: this.perPage,
          sort: [this.sortField, this.sortOrder],
          filters: filters && Object.keys(filters).length > 0 ? `&${new URLSearchParams(filters).toString()}` : '',
        });
        this.total = this.users.totalElements;
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * updateUser
     */
    async updateUser() {
      const payload = { ...this.user };
      delete payload.manager;
      try {
        const data = {
          userId: this.selectedUserId,
          ...payload,
        };
        if (data.userPhoto) {
          if (data.userPhoto.startsWith('http')) {
            data.userPhoto = '';
          }
        } else {
          data.userPhoto = '';
        }
        delete data.password;
        await this.$store.dispatch('Users/updateUser', data);
        await this.fetchUsers();
        this.isEditing = false;
        this.selectedUserId = '';
        this.$store.commit('Users/CLEAR_USER');
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * cancelUpdate
     */
    async cancelUpdate() {
      try {
        await this.fetchUsers();
        this.isEditing = false;
        this.selectedUserId = '';
        this.$store.commit('Users/CLEAR_USER');
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * onPageChange
     *
     * handle page change events
     * @param {number} page
     */
    onPageChange(page) {
      this.page = page;
      this.fetchUsers();
    },

    /**
     * onSort
     *
     * @param {String} field
     * @param {String} order
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.fetchUsers();
    },

    /**
     * onFilter
     *
     * @param {object} filters
     */
    onFilter(filters) {
      this.filters = filters;
      this.fetchUsers();
    },

    /**
     * resetFilters
     *
     */
    resetFilters() {
      this.$refs.usersTable.resetMultiSorting();
      this.$refs.usersTable.filters = {};
      this.searchClause = '';
      this.filters = {};
      this.fetchUsers();
    },

    /**
     * onEdit
     *
     * @param {object} user
     */
    onEdit(userId) {
      this.$store.dispatch('Users/fetchUser', userId);
      this.selectedUserId = userId;
      this.isEditing = true;
    },

    /**
     * searchUser
     *
     */
    searchUser(value) {
      this.searchClause = value;
      this.searchClause = value;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.fetchUsers();
      }, 800);
    },
  },
};
</script>

<style lang="css" scoped>

</style>
