import { render, staticRenderFns } from "./UserGoalsTable.vue?vue&type=template&id=294bac66&scoped=true&"
import script from "./UserGoalsTable.vue?vue&type=script&lang=js&"
export * from "./UserGoalsTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "294bac66",
  null
  
)

export default component.exports