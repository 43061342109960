<template>
  <fieldset>
    <div class="card is-success">
      <header class="card-header">
        <p class="card-header-title">
          goals
        </p>
      </header>

      <div class="card-content">
        <validation-observer
          ref="userGoalsFormValidator"
          v-slot="{ invalid }"
        >
          <div class="columns is-multiline">
            <div class="column is-half">
              <div class="column">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="year"
                >
                  <b-field
                    label="Year"
                    class="is-light is-required"
                    horizontal
                    :type="{ 'is-danger': errors[0] }"
                    :message="errors"
                  >
                    <b-select
                      v-model="year"
                      type="year"
                      expanded
                    >
                      <option
                        v-for="(one, index) in 10"
                        :key="index"
                        :value="currentYear + index"
                      >
                        {{ currentYear + index }}
                      </option>
                    </b-select>
                  </b-field>
                </validation-provider>
              </div>
              <div class="column">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="goal type"
                >
                  <b-field
                    label="Goal Types"
                    class="is-light is-required"
                    horizontal
                    :type="{ 'is-danger': errors[0] }"
                    :message="errors"
                  >
                    <b-select
                      v-model="goalType"
                      type="year"
                      expanded
                    >
                      <option
                        v-for="(type, index) in goalTypesList"
                        :key="index"
                        :value="type"
                      >
                        {{ type.type }}
                      </option>
                    </b-select>
                  </b-field>
                </validation-provider>
              </div>
            </div>
          </div>

          <table class="table is-striped is-fullwidth is-borderless is-spaced-top">
            <thead class="has-background-info">
              <tr>
                <th class="has-text-white">
                  Goal Type
                </th>
                <th class="has-text-white">
                  <span>
                    Sales $
                  </span>
                </th>
                <th class="has-text-white">
                  <span>
                    Sales GP%
                  </span>
                </th>
                <th class="has-text-white">
                  <span>
                    Booking $
                  </span>
                </th>
                <th class="has-text-white">
                  <span>
                    Booking GP%
                  </span>
                </th>
                <th class="has-text-white">
                  <span>
                    Actions
                  </span>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <template>
                  <td>
                    <span v-if="goalType.type">{{ goalType.type }}</span>
                    <span v-else>*Select a goal type</span>
                  </td>
                  <td>
                    <validation-provider
                      v-slot="{ errors }"
                      rules="min_value:0"
                      name="sales"
                    >
                      <b-field
                        :type="{ 'is-danger': errors[0] }"
                        :message="errors"
                      >
                        <currency-input
                          v-model="usersGoalsSales"
                          class="input"
                          :disabled="!goalType.type"
                        />
                      </b-field>
                    </validation-provider>
                  </td>
                  <td>
                    <validation-provider
                      v-slot="{ errors }"
                      rules="max_value:100|min_value:0"
                      name="sales GP%"
                    >
                      <b-field
                        :type="{ 'is-danger': errors[0] }"
                        :message="errors"
                      >
                        <b-numberinput
                          v-model="usersGoalsSalesGp"
                          :controls="false"
                          min="0"
                          max="100"
                          expanded
                          :disabled="!goalType.type"
                        />
                      </b-field>
                    </validation-provider>
                  </td>
                  <td>
                    <validation-provider
                      v-slot="{ errors }"
                      rules="min_value:0"
                      name="booking"
                    >
                      <b-field
                        :type="{ 'is-danger': errors[0] }"
                        :message="errors"
                      >
                        <currency-input
                          v-model="usersGoalsBooking"
                          class="input"
                          :disabled="!goalType.type"
                        />
                      </b-field>
                    </validation-provider>
                  </td>
                  <td>
                    <validation-provider
                      v-slot="{ errors }"
                      rules="max_value:100|min_value:0"
                      name="boooking GP%"
                    >
                      <b-field
                        :type="{ 'is-danger': errors[0] }"
                        :message="errors"
                      >
                        <b-numberinput
                          v-model="usersGoalsBookingGp"
                          :controls="false"
                          min="0"
                          max="100"
                          expanded
                          :disabled="!goalType.type"
                        />
                      </b-field>
                    </validation-provider>
                  </td>
                  <td />
                </template>
              </tr>
              <tr
                v-for="(goal, index) in usersGoals"
                :key="index"
              >
                <template>
                  <td>
                    <span>{{ goal.goalType }}</span>
                  </td>
                  <td>
                    <span>$ {{ goal.sales }}</span>
                  </td>
                  <td>
                    <span>{{ goal.salesGp }}%</span>
                  </td>
                  <td>
                    <span>$ {{ goal.booking }}</span>
                  </td>
                  <td>
                    <span>{{ goal.bookingGp }}%</span>
                  </td>
                  <td>
                    <div class="buttons">
                      <b-tooltip
                        type="is-info"
                        label="Edit"
                        position="is-right"
                      >
                        <b-button
                          type="is-text"
                          class="has-text-grey"
                          icon-right="pencil"
                          @click="onEdit(goal)"
                        />
                      </b-tooltip>
                    </div>
                  </td>
                </template>
              </tr>
              <!-- <tr v-else>
                <td
                  v-for="(col, ind) in 5"
                  :key="ind"
                >
                  --
                </td>
              </tr> -->
            </tbody>
          </table>

          <b-button
            v-if="isEditing === false"
            :disabled="invalid || !hasValidInput"
            type="is-info"
            icon-left="plus"
            aria-label="Add Goal"
            @click="createGoal"
          >
            Add Goal
          </b-button>

          <b-button
            v-if="isEditing === true"
            :disabled="invalid || !hasValidInput"
            type="is-success"
            icon-left="plus"
            aria-label="Save Goal"
            @click="updateGoal"
          >
            Update Goal
          </b-button>
        </validation-observer>
      </div>
    </div>
  </fieldset>
</template>

<script>
// import debounce from 'lodash.debounce';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { mapGetters } from 'vuex';

import debounce from '@/utils/debounce';

export default {

  name: 'UsersGoalsForm',

  components: {
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    inlineEditing: {
      type: Boolean,
      required: false,
    },
  },

  data: () => ({
    accountsSearchResults: [],
    selectedOwner: null,
    isLoading: false,
    usersGoals: [],
    isEditing: false,
    selectedUserGoalId: null,
  }),

  computed: {
    ...mapGetters({
      recurrenceIntervals: 'Lookups/getRecurrenceIntervals',
      goalTypesList: 'Lookups/getGoalTypesList',
      userGoal: 'Organization/getUserGoal',
    }),

    currentYear() {
      const year = new Date().getFullYear() - 1;
      return year;
    },

    hasValidInput() {
      return this.selectedUserId !== null && this.goalType.id !== undefined;
    },

    selectedUserId: {
      get() {
        return this.$store.state.Organization.usersGoals.userId;
      },

      set(value) {
        return this.$store.commit('Organization/SET_USER_ID', value);
      },
    },

    selectedUser: {
      get() {
        return this.$store.state.Organization.usersGoals.userName;
      },

      set(value) {
        return this.$store.commit('Organization/SET_USER_NAME', value.trim());
      },
    },

    year: {
      get() {
        return this.$store.state.Organization.usersGoals.year;
      },

      set(value) {
        return this.$store.commit('Organization/SET_YEAR', value);
      },
    },

    usersGoalsSales: {
      get() {
        return this.$store.state.Organization.usersGoals.usersGoals.sales;
      },

      set(value) {
        return this.$store.commit('Organization/SET_USER_GOALS_SALES', value);
      },
    },

    usersGoalsSalesGp: {
      get() {
        return this.$store.state.Organization.usersGoals.usersGoals.salesGp;
      },

      set(value) {
        return this.$store.commit('Organization/SET_USER_GOALS_SALESGP', value);
      },
    },

    usersGoalsBooking: {
      get() {
        return this.$store.state.Organization.usersGoals.usersGoals.booking;
      },

      set(value) {
        return this.$store.commit('Organization/SET_USER_GOALS_BOOKING', value);
      },
    },

    usersGoalsBookingGp: {
      get() {
        return this.$store.state.Organization.usersGoals.usersGoals.bookingGp;
      },

      set(value) {
        return this.$store.commit('Organization/SET_USER_GOALS_BOOKINGGP', value);
      },
    },

    goalType: {
      get() {
        return this.$store.state.Organization.usersGoals.goalType;
      },

      set(value) {
        return this.$store.commit('Organization/SET_GOAL_TYPE', value);
      },
    },
  },

  watch: {
    async selectedOwner(value) {
      this.$store.commit('Organization/SET_USER_NAME', value.name);
      this.$store.commit('Organization/SET_USER_ID', value.id);
    },

    inlineEditing(value) {
      if (value === false) {
        this.$refs.userGoalsFormValidator.reset();
      }
    },
  },

  async created() {
    await this.$store.dispatch('Lookups/fetchGoalTypesList');
    await this.fetchUserGoals();
    const thisYear = new Date().getFullYear();
    this.$store.commit('Organization/SET_YEAR', thisYear);
  },

  destroyed() {
    this.$store.commit('Organization/CLEAR_USER_GOAL');
  },

  methods: {
    getAccountsAsyncData: debounce(async function (token) {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch('DataGrids/fetchUsers', {
          page: 0,
          size: 50,
          sort: ['name', 'desc'],
          filters: `&${new URLSearchParams({ name: token }).toString()}`,
        });
        this.accountsSearchResults = response;
      } catch (error) {
        this.accountsSearchResults = [];
        throw console.error(error);
      } finally {
        this.isLoading = false;
      }
    }, 500),

    async createGoal() {
      this.isLoading = true;
      let payload = { ...this.userGoal };
      payload = {
        userId: payload.userId,
        year: payload.year,
        goalTypeId: payload.goalType.id,
        sales: payload.usersGoals.sales,
        salesGp: payload.usersGoals.salesGp,
        booking: payload.usersGoals.booking,
        bookingGp: payload.usersGoals.bookingGp,
      };
      try {
        await this.$store.dispatch('Organization/createGoal', payload);
        this.$store.commit('Organization/CLEAR_USER_GOALS');
      } catch (error) {
        console.error(error);
      } finally {
        await this.fetchUserGoals();
        this.$refs.userGoalsFormValidator.reset();
        this.isLoading = false;
      }
    },

    async fetchUserGoals() {
      try {
        const response = await this.$store.dispatch('DataGrids/fetchUserGoals', {
          page: 0,
          size: 100,
          sort: ['updatedAt', 'desc'],
          filters: '',
          userId: this.selectedUserId,
        });
        console.log(response);
        this.usersGoals = response.content;
        // this.total = this.usersGoalsData.totalElements;
      } catch (error) {
        console.error(error);
      }
    },

    onEdit(goalItem) {
      this.$store.dispatch('Organization/fetchGoal', goalItem.id);
      this.selectedUserGoalId = goalItem.id;
      this.$emit('is-editing', true);
      this.isEditing = true;
    },

    async updateGoal() {
      let payload = { ...this.userGoal };
      payload = {
        userId: payload.userId,
        year: payload.year,
        goalTypeId: payload.goalType.id,
        sales: payload.usersGoals.sales,
        salesGp: payload.usersGoals.salesGp,
        booking: payload.usersGoals.booking,
        bookingGp: payload.usersGoals.bookingGp,
      };
      try {
        await this.$store.dispatch('Organization/updateGoal', {
          id: this.selectedUserGoalId,
          goal: payload,
        });
        await this.fetchUserGoals();
        this.isEditing = false;
        this.$emit('is-editing', false);
        this.selectedUserGoalId = null;
        this.$store.commit('Organization/CLEAR_USER_GOAL');
      } catch (error) {
        console.error(error);
      }
    },
  },

};
</script>

<style scoped>
table {
  table-layout: fixed;
  width: 100%;
}

.requiredField:after {
  color: #d22222;
  content: '*';
  display: inline;
}

</style>
