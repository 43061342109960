import { mapGetters, mapActions } from 'vuex';

export default {
  data: () => ({
    isInvalid: false,
    message: '',
    isLoading: true,
  }),

  async created() {
    try {
      if (this.$route.name === 'EditUser') {
        await this.fetchUser(this.$route.params.userId);
        await this.fetchGoal(this.$route.params.userId);
      }
      await this.fetchRoles();
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  },

  computed: mapGetters({
    user: 'Users/getUser',
    goal: 'Users/getGoal',
    roles: 'Security/getRoles',
  }),

  methods: {
    ...mapActions({
      fetchUser: 'Users/fetchUser',
      fetchGoal: 'Users/fetchGoal',
      fetchRoles: 'Security/fetchRoles',
    }),
  },
};
