<template>
  <fieldset>
    <b-field horizontal>
      <div class="columns is-vcentered">
        <div
          v-if="userPhoto"
          class="column is-narrow"
        >
          <b-field>
            <figure class="image is-128x128">
              <img
                style="width:128px; height:128px"
                class="is-rounded"
                :src="userPhoto"
                :alt="username"
              >
            </figure>
          </b-field>
        </div>

        <div class="column is-narrow">
          <b-field class="file is-info">
            <b-upload
              v-model="userPhoto"
              class="file-label"
            >
              <span class="file-cta">
                <b-icon icon="upload" />
                <span class="file-label">upload photo</span>
              </span>
            </b-upload>
          </b-field>
        </div>
      </div>
    </b-field>

    <validation-provider
      v-slot="{ errors }"
      rules="required"
      name="first name"
    >
      <b-field
        horizontal
        label="First Name"
        :type="{ 'is-danger': errors[0] }"
        :message="errors"
        class="is-required"
      >
        <b-input v-model="firstName" />
      </b-field>
    </validation-provider>

    <validation-provider
      v-slot="{ errors }"
      rules=""
      name="middle name"
    >
      <b-field
        horizontal
        label="Middle Name"
        :type="{ 'is-danger': errors[0] }"
        :message="errors"
      >
        <b-input v-model="middleName" />
      </b-field>
    </validation-provider>

    <validation-provider
      v-slot="{ errors }"
      rules="required"
      name="last name"
    >
      <b-field
        horizontal
        label="Last Name"
        :type="{ 'is-danger': errors[0] }"
        :message="errors"
        class="is-required"
      >
        <b-input v-model="lastName" />
      </b-field>
    </validation-provider>

    <validation-provider
      v-slot="{ errors }"
      rules="required"
      name="phone number"
    >
      <b-field
        horizontal
        label="Phone"
        :type="{ 'is-danger': errors[0] }"
        :message="errors"
        class="is-required"
      >
        <vue-tel-input v-model="phoneCell" />
      </b-field>
    </validation-provider>

    <validation-provider
      v-slot="{ errors }"
      rules="required|email"
      name="email address"
    >
      <b-field
        horizontal
        label="Email Address"
        :type="{ 'is-danger': errors[0] }"
        :message="errors"
        class="is-required"
      >
        <b-input
          v-model="email"
          type="email"
          autocomplete="off"
        />
      </b-field>
    </validation-provider>

    <validation-provider
      v-if="$route.name === 'AddUser'"
      v-slot="{ errors }"
      rules="required|min:6"
      name="password"
    >
      <b-field
        horizontal
        label="Password"
        :type="{ 'is-danger': errors[0] }"
        :message="errors"
        class="is-required"
      >
        <b-input
          v-model="password"
          password-reveal
          type="password"
          autocomplete="off"
        />
      </b-field>
    </validation-provider>

    <validation-provider
      v-slot="{ errors }"
      rules=""
      name="external ID"
    >
      <b-field
        horizontal
        label="External ID"
        :type="{ 'is-danger': errors[0] }"
        :message="errors"
      >
        <b-input v-model="externalId" />
      </b-field>
    </validation-provider>

    <b-field
      v-if="$route.name === 'AddUser'"
      horizontal
      label="Reset Password"
    >
      <div class="columns">
        <div class="column is-narrow">
          <b-checkbox-button
            v-model="resetPassword"
            type="is-info"
          >
            <b-icon icon="redo" />
            <span>reset password</span>
          </b-checkbox-button>
        </div>
      </div>
    </b-field>
  </fieldset>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'RolesForm',

  components: {
    ValidationProvider,
  },

  computed: {
    userPhoto: {
      get() {
        return this.$store.state.Users.user.userPhoto;
      },
      set(value) {
        return this.getBase64(value, (imageUrl) => this.$store.commit('Users/SET_USER_USER_PHOTO', imageUrl));
      },
    },

    password: {
      get() {
        return this.$store.state.Users.user.password;
      },
      set(value) {
        return this.$store.commit('Users/SET_USER_PASSWORD', value);
      },
    },

    firstName: {
      get() {
        return this.$store.state.Users.user.firstName;
      },
      set(value) {
        return this.$store.commit('Users/SET_USER_FIRST_NAME', value);
      },
    },

    middleName: {
      get() {
        return this.$store.state.Users.user.middleName;
      },
      set(value) {
        return this.$store.commit('Users/SET_USER_MIDDLE_NAME', value);
      },
    },

    lastName: {
      get() {
        return this.$store.state.Users.user.lastName;
      },
      set(value) {
        return this.$store.commit('Users/SET_USER_LAST_NAME', value);
      },
    },

    email: {
      get() {
        return this.$store.state.Users.user.email;
      },
      set(value) {
        this.$store.commit('Users/SET_USER_USERNAME', value);
        return this.$store.commit('Users/SET_USER_EMAIL', value);
      },
    },

    phoneCell: {
      get() {
        return this.$store.state.Users.user.phoneCell;
      },
      set(value) {
        return this.$store.commit('Users/SET_USER_PHONE_CELL', value);
      },
    },

    externalId: {
      get() {
        return this.$store.state.Users.user.externalId;
      },
      set(value) {
        return this.$store.commit('Users/SET_USER_EXTERNAL_ID', value);
      },
    },

    resetPassword: {
      get() {
        return this.$store.state.Users.user.resetPassword;
      },
      set(value) {
        return this.$store.commit('Users/SET_USER_RESET_PASSWORD', value);
      },
    },
  },

  destroyed() {
    this.$store.commit('Users/CLEAR_USER');
  },

  methods: {
    getBase64(img, callback) {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(img);
    },
  },
};
</script>

<style lang="css" scoped>
</style>
