<template>
  <div class="columns">
    <div class="column is-half">
      <div class="content">
        <h2 class="subtitle has-text-info">
          Organization Information
        </h2>
      </div>

      <div class="columns is-multiline">
        <div class="column is-one-third">
          <div>
            <strong class="heading has-text-weight-bold">Primary User</strong>
            <b-skeleton
              v-if="isLoading"
              :animated="true"
            />
            <template v-else>
              <template v-if="Object.values(organization.primaryUser).length > 0">
                <p class="has-text-grey has-text-weight-semibold">
                  {{ organization.primaryUser.firstName }}
                  {{ organization.primaryUser.lastName }}
                </p>
                <a
                  class="has-text-info has-text-weight-semibold"
                  :href="`mailto:${organization.primaryUser.email}`"
                >
                  {{ organization.primaryUser.email }}
                </a>
              </template>
            </template>
          </div>
        </div>

        <div class="column is-one-third">
          <div>
            <strong class="heading has-text-weight-bold">Street 1</strong>
            <b-skeleton
              v-if="isLoading"
              :animated="true"
            />
            <span
              v-else
              class="has-text-grey has-text-weight-semibold"
            >
              <br>
              {{ organization.street1 }}
            </span>
          </div>
        </div>

        <div class="column is-one-third">
          <div>
            <strong class="heading has-text-weight-bold">Street 2</strong>
            <b-skeleton
              v-if="isLoading"
              :animated="true"
            />
            <span
              v-else
              class="has-text-grey has-text-weight-semibold"
            >
              <br>
              {{ organization.street2 }}
            </span>
          </div>
        </div>

        <div class="column is-one-third">
          <div>
            <strong class="heading has-text-weight-bold">Street 3</strong>
            <b-skeleton
              v-if="isLoading"
              :animated="true"
            />
            <span
              v-else
              class="has-text-grey has-text-weight-semibold"
            >
              <br>
              {{ organization.street3 }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="column">
      <div class="content">
        <h2 class="subtitle has-text-info">
          Locations
        </h2>
      </div>

      <div
        v-if="isLoading"
        class="columns is-multiline"
      >
        <div
          v-for="i in 3"
          :key="i"
          class="column is-one-third"
        >
          <b-skeleton :animated="true" />

          <b-skeleton
            :animated="true"
            width="50%"
          />
        </div>
      </div>

      <div
        v-else
        class="columns is-multiline"
      >
        <div
          v-for="location in organization.locations"
          :key="location.name"
          class="column is-one-third"
        >
          <span class="has-text-grey has-text-weight-semibold">
            <strong class="heading has-text-weight-bold">{{ location.name }}</strong>
            <br>
            <span>{{ location.timezone.value }}</span>
            <br>
            <b-tag :type="location.isActive ? 'is-success' : 'is-light'">
              {{ location.isActive ? 'active' : 'inactive' }}
            </b-tag>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrganizationOverview',

  props: {
    organization: {
      type: Object,
      required: true,
    },

    isLoading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style></style>
