<template>
  <fieldset>
    <validation-provider
      v-slot="{ errors }"
      rules="required"
      name="role"
    >
      <b-field
        label="Assign to Role"
        :type="{ 'is-danger': errors[0] }"
        :message="errors"
        class="is-primary is-required"
      >
        <b-select
          v-model="role"
          expanded
        >
          <option
            v-for="(target, index) in roles"
            :key="index"
            :value="target.name"
            class="is-capitalized"
          >
            {{ target.name | role }}
          </option>
        </b-select>
      </b-field>
    </validation-provider>

    <validation-provider
      v-if="role"
      name="manager"
    >
      <b-field
        grouped
        label="Assign to Manager"
        class="is-primary"
      >
        <b-autocomplete
          v-model="manager"
          icon-right="search"
          expanded
          field="fullName"
          :data="usersSearchResults"
          :loading="isLoading"
          @typing="getUsersAsyncData"
          @select="option => selectedUser = option"
        >
          <template slot-scope="props">
            <div class="media">
              <div class="media-content">
                {{ props.option.fullName.trim() }}
                <br>
                <small>
                  email: {{ props.option.email }} <br>
                  Phone: {{ props.option.phoneCell }}
                </small>
              </div>
            </div>
          </template>

          <template #empty>
            No results for {{ manager }}
          </template>
        </b-autocomplete>

        <div
          v-if="manager"
          @click="clearManager"
        >
          <b-button
            type="is-danger"
            icon-right="close"
          />
        </div>
      </b-field>
    </validation-provider>
  </fieldset>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

import debounce from '@/utils/debounce';

export default {
  name: 'UsersMembershipForm',

  components: {
    ValidationProvider,
  },

  filters: {
    role(value) {
      return value.replace(/_/g, ' ').substring(5).toLowerCase();
    },
  },

  props: {
    roles: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    isLoading: false,
    selectedUser: null,
    usersSearchResults: [],
  }),

  computed: {
    role: {
      get() {
        return this.$store.state.Users.user.roles[0];
      },
      set(value) {
        return this.$store.commit('Users/SET_USER_ROLE', value);
      },
    },

    manager: {
      get() {
        return this.$store.state.Users.user.manager;
      },
      set(value) {
        return this.$store.commit('Users/SET_USER_MANAGER', value);
      },
    },
  },

  watch: {
    selectedUser(value) {
      return this.$store.commit('Users/SET_USER_MANAGER_ID', value.userId);
    },
  },

  methods: {
    getUsersAsyncData: debounce(async function (token) {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch('Search/searchUsers', token);
        this.usersSearchResults = response;
      } catch (error) {
        this.usersSearchResults = [];
      } finally {
        this.isLoading = false;
      }
    }, 500),

    clearManager() {
      this.$store.commit('Users/SET_USER_MANAGER_ID', '');
      return this.$store.commit('Users/SET_USER_MANAGER', '');
    },
  },
};
</script>

<style>

</style>
