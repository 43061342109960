<template>
  <div class="card is-primary">
    <header class="card-header">
      <p class="card-header-title">
        Divisions Form
      </p>

      <b-button
        type="is-text"
        icon-right="close"
        class="has-text-info"
        @click="$emit('close')"
      />
    </header>

    <div class="card-content">
      <form @submit.prevent="$emit('save-division')">
        <b-field
          label="Name"
          class="is-primary"
        >
          <b-input v-model="name" />
        </b-field>

        <b-field>
          <b-button
            native-type="submit"
            type="is-info"
            expanded
          >
            save division
          </b-button>
        </b-field>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DivisionsForm',

  computed: {
    name: {
      get() {
        return this.$store.state.Divisions.division.name;
      },

      set(value) {
        return this.$store.commit('Divisions/SET_DIVISION_NAME', value);
      },
    },
  },

  destroyed() {
    this.$store.commit('Divisions/CLEAR_DIVISION');
  },
};
</script>

<style></style>
