<template>
  <dashboard-layout>
    <template #content>
      <router-view />
    </template>
  </dashboard-layout>
</template>

<script>
export default {
  name: 'Organization',

  components: {
    DashboardLayout: () => import('../../layouts/DashboardLayout.vue'),
  },

};
</script>

<style>

</style>
