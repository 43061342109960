import { mapGetters } from 'vuex';

export default {

  data: () => ({
    isDivisionsFormModalActive: false,
    isEditingDivision: false,
    currentDivisionsPage: 1,
    perPage: 6,
    divisionsTotal: 0,
  }),

  computed: mapGetters({
    divisionsLoading: 'Divisions/getLoading',
    divisions: 'Divisions/getDivisions',
    division: 'Divisions/getDivision',
  }),

  methods: {
    /**
     * fetchDivisions
     *
     * fetch all the organization's divisions
     *
     * @return {JSON}
     */
    async fetchDivisions() {
      try {
        const response = await this.$store.dispatch('Divisions/fetchDivisions', {
          page: this.currentDivisionsPage - 1,
          perPage: this.perPage,
        });
        this.divisionsTotal = response.totalElements;
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * onDivisionsPageChange
     *
     * fetch divisions on the selected page
     *
     * @param {Int} page
     */
    onDivisionsPageChange(page) {
      this.currentDivisionsPage = page;
      this.fetchDivisions();
    },

    /**
     * createDivision
     *
     * create an organization division
     */
    async createDivision() {
      try {
        await this.$store.dispatch('Divisions/createDivision', this.division.name);
        this.isDivisionsFormModalActive = false;
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * editDivision
     *
     * open division's modal
     *
     * @param {Object} division
     */
    editDivision(division) {
      this.isEditingDivision = true;
      this.$store.commit('Divisions/SET_DIVISION', division);
      this.isDivisionsFormModalActive = true;
    },

    /**
     * updateDivision
     *
     * update organization's division
     */
    async updateDivision() {
      try {
        await this.$store.dispatch('Divisions/updateDivision', this.division);
        this.isEditingDivision = false;
        this.isDivisionsFormModalActive = false;
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * deleteDivision
     *
     * delete an organization's division by it's ID
     *
     * @param {Int} divisionId
     */
    deleteDivision(divisionId) {
      this.$buefy.dialog.confirm({
        title: 'Deleting division',
        message: 'Are you sure you want to <b>delete</b> this division? This action cannot be undone.',
        confirmText: 'Delete Division',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            await this.$store.dispatch('Divisions/deleteDivision', divisionId);
            await this.$store.dispatch('Users/fetchUsers');
          } catch (error) {
            console.error(error);
          }
        },
      });
    },

    /**
     * addDivisionMember
     *
     * assign a user to a division by their ID
     *
     * @param {Int} memberId
     */
    async addDivisionMember(memberId) {
      try {
        await this.$store.dispatch('Divisions/addDivisionMember', memberId);
        await this.$store.dispatch('Users/fetchUsers');
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * deleteDivisionMember
     *
     * remove a user from a division using their user ID
     *
     * @param {Int} memberId
     */
    deleteDivisionMember(memberId) {
      this.$buefy.dialog.confirm({
        title: 'Deleting division member',
        message: 'Are you sure you want to <b>delete</b> this division member? This action cannot be undone.',
        confirmText: 'Delete Division Member',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            await this.$store.dispatch('Divisions/deleteDivisionMember', memberId);
            await this.$store.dispatch('Users/fetchUsers');
          } catch (error) {
            console.error(error);
          }
        },
      });
    },
  },

  mounted() {
    this.fetchDivisions();
  },

};
