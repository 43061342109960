<template>
  <div class="card is-primary">
    <header class="card-header">
      <p class="card-header-title">
        {{ division.name }}
      </p>

      <b-tooltip
        type="is-info"
        label="Add Member"
        position="is-bottom"
      >
        <b-button
          v-if="hasPermissions('update_teams')"
          icon-right="user-plus"
          type="is-text"
          @click="isDivisionMemberFormActive = !isDivisionMemberFormActive"
        />
      </b-tooltip>

      <b-tooltip
        type="is-info"
        label="Update Division"
        position="is-bottom"
      >
        <b-button
          v-if="hasPermissions('update_teams')"
          icon-right="pencil"
          type="is-text"
          @click="$emit('edit-division', division)"
        />
      </b-tooltip>

      <b-tooltip
        type="is-info"
        label="Delete Division"
        position="is-bottom"
      >
        <b-button
          v-if="hasPermissions('delete_teams')"
          icon-right="trash-can"
          type="is-text"
          @click="$emit('delete-division', division.id)"
        />
      </b-tooltip>
    </header>

    <div class="card-content">
      <!-- contacts form component -->
      <div
        v-if="isDivisionMemberFormActive"
        class="columns"
      >
        <div class="column">
          <b-field class="is-primary">
            <b-autocomplete
              v-model="user"
              icon-right="search"
              expanded
              field="fullName"
              :data="usersSearchResults"
              :loading="isLoading"
              @typing="getUsersAsyncData"
              @select="(option) => (selectedUser = option)"
            >
              <template slot-scope="props">
                <div class="media">
                  <div class="media-content">
                    {{ `${props.option.firstName} ${props.option.lastName}` }}
                    <br>
                    <small>
                      email: {{ props.option.email }} <br>
                      Phone: {{ props.option.phoneCell }}
                    </small>
                  </div>
                </div>
              </template>

              <template #empty>
                No results for {{ user }}
              </template>
            </b-autocomplete>
          </b-field>
        </div>
        <div class="column is-narrow">
          <b-button
            type="is-text"
            icon-right="user-plus"
            class="has-text-white"
            @click="addDivisionMember(division.id)"
          />
        </div>
      </div>

      <!-- contacts list component -->
      <div
        v-for="(member, index) in division.members"
        :key="index"
        class="media"
      >
        <figure class="media-left">
          <p class="image is-32x32">
            <img
              style="width:32px; height:32px;"
              class="is-rounded"
              :src="member.userPhoto ? member.userPhoto : defaultImage"
              :alt="member.username"
            >
          </p>
        </figure>

        <div class="media-content">
          <div class="content">
            <p>
              <strong class="has-text-white">
                {{ `${member.firstName} ${member.lastName}` }}
              </strong>
              <br>
              <small>{{ member.email }}</small>
            </p>
          </div>
        </div>

        <div
          v-if="hasPermissions('update_teams')"
          class="media-right"
        >
          <b-tooltip
            type="is-info"
            label="remove member"
            position="is-bottom"
          >
            <b-button
              icon-right="close"
              type="is-text"
              @click="
                $emit('delete-division-member', {
                  divisionId: division.id,
                  userId: member.id,
                })
              "
            />
          </b-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import defaultImage from '@/assets/images/avatar.svg';

import debounce from '@/utils/debounce';

export default {
  name: 'DivisionsCard',

  props: {
    division: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    isLoading: false,
    selectedUser: null,
    usersSearchResults: [],
    user: '',
    defaultImage,
    isDivisionMemberFormActive: false,
  }),

  watch: {
    selectedUser(value) {
      return this.$store.commit('Divisions/SET_USER_ID', value.userId);
    },
  },

  methods: {
    getUsersAsyncData: debounce(async function (token) {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch('Search/searchUsers', token);
        this.usersSearchResults = response;
      } catch (error) {
        this.usersSearchResults = [];
      } finally {
        this.isLoading = false;
      }
    }, 500),

    addDivisionMember(divisionId) {
      this.$emit('add-division-member', {
        divisionId,
        userId: this.$store.state.Divisions.userId,
      });
      this.user = '';
      this.isDivisionMemberFormActive = false;
    },
  },
};
</script>

<style></style>
