<template>
  <div class="card is-primary">
    <header class="card-header">
      <p class="card-header-title">
        Teams Form
      </p>

      <b-button
        type="is-text"
        icon-right="close"
        class="has-text-info"
        @click="$emit('close')"
      />
    </header>

    <div class="card-content">
      <form @submit.prevent="$emit('save-team')">
        <b-field
          label="Name"
          class="is-primary"
        >
          <b-input v-model="name" />
        </b-field>

        <b-field>
          <b-button
            native-type="submit"
            type="is-info"
            expanded
          >
            save team
          </b-button>
        </b-field>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TeamsForm',

  computed: {
    name: {
      get() {
        return this.$store.state.Teams.team.name;
      },

      set(value) {
        return this.$store.commit('Teams/SET_TEAM_NAME', value);
      },
    },
  },

  destroyed() {
    this.$store.commit('Teams/CLEAR_TEAM');
  },
};
</script>

<style></style>
