import { mapGetters } from 'vuex';

export default {

  data: () => ({
    isTeamsFormModalActive: false,
    isEditingTeam: false,
    currentTeamsPage: 1,
    perPage: 6,
    teamsTotal: 0,
  }),

  computed: mapGetters({
    isLoading: 'Teams/getLoading',
    teams: 'Teams/getTeams',
    team: 'Teams/getTeam',
  }),

  methods: {
    /**
     * fetchTeams
     *
     * fetch all the organization's teams
     *
     * @return {JSON}
     */
    async fetchTeams() {
      try {
        const response = await this.$store.dispatch('Teams/fetchTeams', {
          page: this.currentTeamsPage - 1,
          perPage: this.perPage,
        });
        this.teamsTotal = response.totalElements;
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * onTeamsPageChange
     *
     * fetch teams on the selected page
     *
     * @param {Int} page
     */
    onTeamsPageChange(page) {
      this.currentTeamsPage = page;
      this.fetchTeams();
    },

    /**
     * createTeam
     *
     * create a new team
     */
    async createTeam() {
      try {
        await this.$store.dispatch('Teams/createTeam', this.$store.state.Teams.team.name);
        this.isTeamsFormModalActive = false;
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * editTeam
     *
     * Open the teams modal
     * Set the team to teh selected team in the parameter
     *
     * @param {Object} team
     */
    editTeam(team) {
      this.isEditingTeam = true;
      this.$store.commit('Teams/SET_TEAM', team);
      this.isTeamsFormModalActive = true;
    },

    /**
     * updateTeam
     *
     * update an existing team's name
     *
     * @return {JSON} update team's details
     */
    async updateTeam() {
      try {
        await this.$store.dispatch('Teams/updateTeam', {
          id: this.$store.state.Teams.team.id,
          name: this.$store.state.Teams.team.name,
        });
        this.isEditingTeam = false;
        this.isTeamsFormModalActive = false;
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * deleteTeam
     *
     * delete an existing team by it's id
     *
     * @param {Int} teamId
     */
    deleteTeam(teamId) {
      this.$buefy.dialog.confirm({
        title: 'Deleting team',
        message: 'Are you sure you want to <b>delete</b> this team? This action cannot be undone.',
        confirmText: 'Delete Team',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            await this.$store.dispatch('Teams/deleteTeam', teamId);
          } catch (error) {
            console.error(error);
          }
        },
      });
    },

    /**
     * addTeamMember
     *
     * assign a user to team by their ID
     *
     * @param {Object} payload
     * @return {JSON}
     */
    async addTeamMember(payload) {
      try {
        await this.$store.dispatch('Teams/addTeamMember', payload);
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * deleteTeamMember
     *
     * delete a user from a team by their ID
     *
     * @param {Int} memberId
     */
    deleteTeamMember(memberId) {
      this.$buefy.dialog.confirm({
        title: 'Deleting team member',
        message: 'Are you sure you want to <b>delete</b> this team member? This action cannot be undone.',
        confirmText: 'Delete Team Member',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            await this.$store.dispatch('Teams/deleteTeamMember', memberId);
          } catch (error) {
            console.error(error);
          }
        },
      });
    },
  },

  mounted() {
    this.fetchTeams();
  },
};
