import { mapGetters, mapActions } from 'vuex';

export default {
  data: () => ({
    isLoading: true,
  }),

  async created() {
    try {
      await this.fetchStats();
      if (this.hasPermissions('read_organizations')) {
        await this.fetchOrganization();
      }
      if (this.hasPermissions('read_roles')) {
        await this.fetchRoles();
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  },

  computed: {
    ...mapGetters({
      organization: 'Organization/getOrganization',
      roles: 'Security/getRoles',
      stats: 'Organization/getStats',
    }),
  },

  methods: {
    ...mapActions({
      fetchOrganization: 'Organization/fetchOrganization',
      fetchRoles: 'Security/fetchRoles',
      fetchStats: 'Organization/fetchStats',
    }),
  },
};
