<template>
  <fieldset>
    <div class="card is-success">
      <header class="card-header">
        <p class="card-header-title">
          goals
        </p>
      </header>

      <div class="card-content">
        <validation-observer
          ref="userGoalsFormValidator"
          v-slot="{ invalid }"
        >
          <div class="columns is-multiline">
            <div class="column is-half">
              <div class="column">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="user"
                >
                  <b-field
                    label="User"
                    class="is-light is-required"
                    horizontal
                    :type="{ 'is-danger': errors[0] }"
                    :message="errors"
                  >
                    <b-autocomplete
                      id="owner"
                      v-model="selectedUser"
                      icon-right="search"
                      expanded
                      field="fullName"
                      :loading="isLoading"
                      :data="accountsSearchResults"
                      @typing="getAccountsAsyncData"
                      @select="(option) => (selectedOwner = option)"
                    >
                      <template slot-scope="props">
                        <div class="media">
                          <div class="media-content">
                            {{ props.option.name }}
                            <br>
                            <small>
                              email: {{ props.option.email }} <br>
                              Phone: {{ props.option.phoneCell }}
                            </small>
                          </div>
                        </div>
                      </template>

                      <template #empty>
                        No results for {{ selectedUser }}
                      </template>
                    </b-autocomplete>
                  </b-field>
                </validation-provider>
              </div>
              <div class="column">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="year"
                >
                  <b-field
                    label="Year"
                    class="is-light is-required"
                    horizontal
                    :type="{ 'is-danger': errors[0] }"
                    :message="errors"
                  >
                    <b-select
                      v-model="year"
                      type="year"
                      expanded
                    >
                      <option
                        v-for="(one, index) in 10"
                        :key="index"
                        :value="currentYear + index"
                      >
                        {{ currentYear + index }}
                      </option>
                    </b-select>
                  </b-field>
                </validation-provider>
              </div>
              <div class="column">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="goal total"
                >
                  <b-field
                    label="Goal Total"
                    class="is-light is-required"
                    horizontal
                    :type="{ 'is-danger': errors[0] }"
                    :message="errors"
                  >
                    <b-input
                      v-model="goalTotal"
                      expanded
                    />
                  </b-field>
                </validation-provider>
              </div>
              <div class="column">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="goal type"
                >
                  <b-field
                    label="Goal Types"
                    class="is-light is-required"
                    horizontal
                    :type="{ 'is-danger': errors[0] }"
                    :message="errors"
                  >
                    <b-select
                      v-model="goalType"
                      type="year"
                      expanded
                    >
                      <option
                        v-for="(type, index) in goalTypesList"
                        :key="index"
                        :value="type"
                      >
                        {{ type.type }}
                      </option>
                    </b-select>
                  </b-field>
                </validation-provider>
              </div>
            </div>

            <div class="column is-half">
              <validation-provider
                name="user"
              >
                <b-field
                  label="Linked Sales IDs"
                  class="is-light"
                  horizontal
                >
                  <b-autocomplete
                    id="owner"
                    v-model="linkedSalesIds"
                    icon-right="search"
                    expanded
                    field="salesID"
                    :open-on-focus="true"
                    :loading="isLoading"
                    :data="linkedIdsSearchResults"
                    @select="(option) => (selectedSalesId = option)"
                  >
                    <template slot-scope="props">
                      <template v-if="props.option.description !== null">
                        {{ props.option.salesID }} - {{ props.option.description }}
                      </template>
                      <template v-else>
                        {{ props.option.salesID }}
                      </template>
                    </template>

                    <template #empty>
                      No linked IDs
                    </template>
                  </b-autocomplete>
                </b-field>
              </validation-provider>
            </div>

            <div class="column is-full">
              <div class="columns is-multiline">
                <div class="column is-full">
                  <div class="column">
                    <b-field
                      label="Period Breakdown (optional)"
                      class="is-light"
                      horizontal
                    />
                  </div>
                </div>
                <div class="column is-half">
                  <div class="column">
                    <b-field
                      label="Distribute"
                      class="is-light distribute_field"
                      horizontal
                    >
                      <div class="block">
                        <b-checkbox
                          native-value="year"
                          :value="distribution"
                          type="is-info"
                          @input="setDistribution"
                        >
                          YR
                        </b-checkbox>
                        <b-checkbox
                          v-for="(num, index) in 4"
                          :key="index"
                          :native-value="'Q'+ num"
                          :value="distribution"
                          type="is-info"
                          @input="setDistribution"
                        >
                          Q{{ num }}
                        </b-checkbox>
                      </div>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-button
                      type="is-info"
                      icon-left="plus"
                      aria-label="Add Period"
                      @click="addPeriod"
                    >
                      Add Period
                    </b-button>
                  </div>
                </div>
                <div class="column is-half">
                  <div class="column">
                    <b-field
                      label=""
                      horizontal
                    >
                      <div class="period_breakdown_card">
                        <div class="level">
                          <div class="level-left">
                            <div class="level-item">
                              <label class="label">Breakdown</label>
                            </div>
                          </div>
                          <div class="level-right">
                            <div class="level-item">
                              <b-switch
                                v-if="periodBreakdown.length > 0"
                                v-model="isEditingBreakdown"
                                type="is-info"
                                size="small"
                              >
                                {{ isEditingBreakdown ? 'Editing' : 'Not Editing' }}
                              </b-switch>
                            </div>
                          </div>
                        </div>
                        <div class="pb_list">
                          <div
                            v-for="(item, index) in periodBreakdown"
                            :key="index"
                            class="level"
                          >
                            <div class="level-left">
                              <div class="level-item">
                                {{ item.period }}
                              </div>
                            </div>
                            <div class="level-right">
                              <div
                                v-if="isEditingBreakdown"
                                class="level-item"
                              >
                                <currency-input
                                  v-model="periodBreakdown[index]['amount']"
                                  size="small"
                                  class="input"
                                />
                              </div>
                              <div
                                v-else
                                class="level-item"
                              >
                                {{ '$' + item.amount }}
                              </div>
                              <div class="level-item">
                                <b-button
                                  aria-label="Clear period"
                                  type="is-danger"
                                  icon-right="close"
                                  @click="() => clearPeriodBreakdownItem(index)"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-field>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <table class="table is-striped is-fullwidth is-borderless is-spaced-top">
            <thead class="has-background-info">
              <tr>
                <th class="has-text-white">
                  User
                </th>
                <th class="has-text-white">
                  Sales ID
                </th>
                <th class="has-text-white">
                  Year
                </th>
                <th class="has-text-white">
                  Period
                </th>
                <th class="has-text-white">
                  <span>
                    Sales $
                  </span>
                </th>
                <th class="has-text-white">
                  <span>
                    Sales GP%
                  </span>
                </th>
                <th class="has-text-white">
                  <span>
                    Booking $
                  </span>
                </th>
                <th class="has-text-white">
                  <span>
                    Booking GP%
                  </span>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-if="goalType.type"
              >
                <template>
                  <td>
                    <span>{{ selectedUser || '--' }}</span>
                  </td>
                  <td>
                    <span>{{ linkedSalesIds || '--' }}</span>
                  </td>
                  <td>
                    <span>{{ year || '--' }}</span>
                  </td>
                  <td>
                    <span>Annual</span>
                  </td>
                  <td>
                    <validation-provider
                      v-slot="{ errors }"
                      rules="min_value:0"
                      name="sales"
                    >
                      <b-field
                        :type="{ 'is-danger': errors[0] }"
                        :message="errors"
                      >
                        <currency-input
                          v-model="usersGoalsSales"
                          class="input"
                        />
                      </b-field>
                    </validation-provider>
                  </td>
                  <td>
                    <validation-provider
                      v-slot="{ errors }"
                      rules="max_value:100|min_value:0"
                      name="sales GP%"
                    >
                      <b-field
                        :type="{ 'is-danger': errors[0] }"
                        :message="errors"
                      >
                        <b-numberinput
                          v-model="usersGoalsSalesGp"
                          :controls="false"
                          min="0"
                          max="100"
                          expanded
                        />
                      </b-field>
                    </validation-provider>
                  </td>
                  <td>
                    <validation-provider
                      v-slot="{ errors }"
                      rules="min_value:0"
                      name="booking"
                    >
                      <b-field
                        :type="{ 'is-danger': errors[0] }"
                        :message="errors"
                      >
                        <currency-input
                          v-model="usersGoalsBooking"
                          class="input"
                        />
                      </b-field>
                    </validation-provider>
                  </td>
                  <td>
                    <validation-provider
                      v-slot="{ errors }"
                      rules="max_value:100|min_value:0"
                      name="boooking GP%"
                    >
                      <b-field
                        :type="{ 'is-danger': errors[0] }"
                        :message="errors"
                      >
                        <b-numberinput
                          v-model="usersGoalsBookingGp"
                          :controls="false"
                          min="0"
                          max="100"
                          expanded
                        />
                      </b-field>
                    </validation-provider>
                  </td>
                </template>
              </tr>
              <tr v-else>
                <td
                  v-for="(col, ind) in 8"
                  :key="ind"
                >
                  --
                </td>
              </tr>
            </tbody>
          </table>

          <b-button
            v-if="inlineEditing === false"
            :disabled="invalid || !hasValidInput"
            type="is-info"
            icon-left="plus"
            aria-label="Add Goal"
            @click="createGoal"
          >
            Add Goal
          </b-button>

          <b-button
            v-if="inlineEditing === true"
            :disabled="invalid || !hasValidInput"
            type="is-success"
            icon-left="plus"
            aria-label="Save Goal"
            @click="updateGoal"
          >
            Update Goal
          </b-button>
        </validation-observer>
      </div>
    </div>
  </fieldset>
</template>

<script>
// import debounce from 'lodash.debounce';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { mapGetters } from 'vuex';

import debounce from '@/utils/debounce';

export default {

  name: 'UsersGoalsForm',

  components: {
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    inlineEditing: {
      type: Boolean,
      required: false,
    },
  },

  data: () => ({
    accountsSearchResults: [],
    linkedIdsSearchResults: [],
    selectedOwner: null,
    selectedSalesId: null,
    isLoading: false,
    isLoadingLinkedIds: false,
    periodBreakdown: [],
    isEditingBreakdown: false,
  }),

  computed: {
    ...mapGetters({
      recurrenceIntervals: 'Lookups/getRecurrenceIntervals',
      goalTypesList: 'Lookups/getGoalTypesList',
      userGoal: 'Organization/getUserGoal',
    }),

    currentYear() {
      const year = new Date().getFullYear() - 1;
      return year;
    },

    hasValidInput() {
      return this.selectedUserId !== null && this.goalType.id !== undefined;
    },

    selectedUserId: {
      get() {
        return this.$store.state.Organization.usersGoals.userId;
      },

      set(value) {
        return this.$store.commit('Organization/SET_USER_ID', value);
      },
    },

    selectedUser: {
      get() {
        return this.$store.state.Organization.usersGoals.userName;
      },

      set(value) {
        return this.$store.commit('Organization/SET_USER_NAME', value.trim());
      },
    },

    linkedId: {
      get() {
        return this.$store.state.Organization.usersGoals.linkedId;
      },

      set(value) {
        return this.$store.commit('Organization/SET_LINKED_ID', value);
      },
    },

    year: {
      get() {
        return this.$store.state.Organization.usersGoals.year;
      },

      set(value) {
        return this.$store.commit('Organization/SET_YEAR', value);
      },
    },

    usersGoalsSales: {
      get() {
        return this.$store.state.Organization.usersGoals.usersGoals.sales;
      },

      set(value) {
        return this.$store.commit('Organization/SET_USER_GOALS_SALES', value);
      },
    },

    usersGoalsSalesGp: {
      get() {
        return this.$store.state.Organization.usersGoals.usersGoals.salesGp;
      },

      set(value) {
        return this.$store.commit('Organization/SET_USER_GOALS_SALESGP', value);
      },
    },

    usersGoalsBooking: {
      get() {
        return this.$store.state.Organization.usersGoals.usersGoals.booking;
      },

      set(value) {
        return this.$store.commit('Organization/SET_USER_GOALS_BOOKING', value);
      },
    },

    usersGoalsBookingGp: {
      get() {
        return this.$store.state.Organization.usersGoals.usersGoals.bookingGp;
      },

      set(value) {
        return this.$store.commit('Organization/SET_USER_GOALS_BOOKINGGP', value);
      },
    },

    linkedSalesIds: {
      get() {
        return this.$store.state.Organization.usersGoals.linkedSalesIds;
      },

      set(value) {
        return this.$store.commit('Organization/SET_LINKED_SALES_IDS', value);
      },
    },

    goalType: {
      get() {
        return this.$store.state.Organization.usersGoals.goalType;
      },

      set(value) {
        return this.$store.commit('Organization/SET_GOAL_TYPE', value);
      },
    },

    goalTotal: {
      get() {
        return this.$store.state.Organization.usersGoals.goalTotal;
      },

      set(value) {
        console.log(`Goal Total is ${value}`);
        return this.$store.commit('Organization/SET_GOAL_TOTAL', value);
      },
    },

    periodGoalTotal: {
      get() {
        return this.$store.state.Organization.usersGoals.periodGoalTotal;
      },

      set(value) {
        return this.$store.commit('Organization/SET_PERIOD_GOAL_TOTAL', value);
      },
    },

    fiscalYear: {
      get() {
        return this.$store.state.Organization.usersGoals.fiscalYear;
      },

      set(value) {
        return this.$store.commit('Organization/SET_FISCAL_YEAR', value);
      },
    },

    distribution: {
      get() {
        return this.$store.state.Organization.usersGoals.distribution;
      },

      set(value) {
        return this.$store.commit('Organization/SET_DISTRIBUTION', value);
      },
    },
  },

  watch: {
    async selectedOwner(value) {
      console.log(value.name);
      this.$store.commit('Organization/SET_USER_NAME', value.name);
      this.$store.commit('Organization/SET_USER_ID', value.id);
      await this.getLinkedIdsAsyncData(value.id);
    },

    async selectedSalesId(value) {
      this.$store.commit('Organization/SET_LINKED_SALES_IDS', value);
    },

    distribution(value) {
      if (value.length > 0) {
        this.fiscalYear = null;
        this.periodGoalTotal = null;
      }
    },

    fiscalYear(value) {
      if (value) {
        this.distribution = [];
      }
    },

    inlineEditing(value) {
      if (value === false) {
        this.$refs.userGoalsFormValidator.reset();
      }
    },

    periodBreakdown() {
      if (!this.usersGoalsSales && !this.usersGoalsBooking) {
        this.usersGoalsSales = this.goalTotal;
        this.usersGoalsBooking = this.goalTotal;
      }
    },
  },

  async created() {
    await this.$store.dispatch('Lookups/fetchGoalTypesList');

    const thisYear = new Date().getFullYear();
    this.$store.commit('Organization/SET_YEAR', thisYear);
  },

  destroyed() {
    this.$store.commit('Organization/CLEAR_USER_GOAL');
    this.$store.commit('Organization/SET_USER_NAME', '');
  },

  methods: {
    addPeriod() {
      if (this.fiscalYear && this.periodGoalTotal) {
        const filtered = this.periodBreakdown.filter((i) => (i.period === `Period ${this.fiscalYear}`));
        if (filtered.length > 0) {
          const index = this.periodBreakdown.indexOf(filtered[0]);
          const copy = [
            ...this.periodBreakdown,
          ];
          copy[index] = {
            period: `Period ${this.fiscalYear}`,
            amount: this.periodGoalTotal,
          };
          this.periodBreakdown = copy;
        } else {
          this.periodBreakdown.push({
            period: `Period ${this.fiscalYear}`,
            amount: this.periodGoalTotal,
          });
        }
      } else if (this.distribution && this.goalTotal) {
        const period = [];
        if (this.distribution.includes('year')) {
          period.push(1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12);
        } else {
          if (this.distribution.includes('Q1')) {
            period.push(1, 2, 3);
          }
          if (this.distribution.includes('Q2')) {
            period.push(4, 5, 6);
          }
          if (this.distribution.includes('Q3')) {
            period.push(7, 8, 9);
          }
          if (this.distribution.includes('Q4')) {
            period.push(10, 11, 12);
          }
        }
        const breakdown = parseFloat(this.goalTotal / period.length)
          .toFixed(2);
        this.periodBreakdown = period.map((i) => ({
          period: `Period ${i}`,
          amount: breakdown,
        }));
      }
    },

    resetFields() {
      this.selectedUser.set(null);
      this.$refs.userGoalsFormValidator.reset();
    },

    setDistribution(value) {
      if (value[value.length - 1] === 'year') {
        this.$store.commit('Organization/SET_DISTRIBUTION', ['year']);
      } else if (value.includes('year')) {
        this.$store.commit('Organization/SET_DISTRIBUTION', value.filter((i) => i !== 'year'));
      } else {
        this.$store.commit('Organization/SET_DISTRIBUTION', value);
      }
    },

    clearPeriodBreakdownItem(index) {
      this.periodBreakdown.splice(index, 1);
    },

    getAccountsAsyncData: debounce(async function (token) {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch('DataGrids/fetchUsers', {
          page: 0,
          size: 50,
          sort: ['name', 'desc'],
          filters: `&${new URLSearchParams({ name: token }).toString()}`,
        });
        this.accountsSearchResults = response;
      } catch (error) {
        this.accountsSearchResults = [];
        throw console.error(error);
      } finally {
        this.isLoading = false;
      }
    }, 500),

    getLinkedIdsAsyncData: debounce(async function (token) {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch('Organization/fetchUserLinkedIds', {
          page: 0,
          size: 50,
          sort: ['salesID', 'desc'],
          filters: `&${new URLSearchParams({ userId: token }).toString()}`,
        });
        this.linkedIdsSearchResults = response.data;
      } catch (error) {
        this.linkedIdsSearchResults = [];
        throw console.error(error);
      } finally {
        this.isLoading = false;
      }
    }, 500),

    async createGoal() {
      this.isLoading = true;
      let payload = { ...this.userGoal };
      const objectentries = this.periodBreakdown.map((i) => (
        [i.period.split(' ')
          .join('')
          .toLowerCase(), parseFloat(i.amount)]
      ));
      const periods = Object.fromEntries(objectentries);
      payload = {
        userId: payload.userId,
        year: payload.year,
        goalTypeId: payload.goalType.id,
        sales: payload.usersGoals.sales,
        salesGp: payload.usersGoals.salesGp,
        booking: payload.usersGoals.booking,
        bookingGp: payload.usersGoals.bookingGp,
        goalTotal: this.goalTotal,
        linkedSalesIds: this.linkedSalesIds,
        ...periods,
      };
      try {
        await this.$store.dispatch('Organization/createGoal', payload);
        this.$refs.userGoalsFormValidator.reset();
        this.$store.commit('Organization/CLEAR_USER_GOAL');
        this.periodBreakdown = [];
      } catch (error) {
        console.error(error);
      } finally {
        this.$emit('fetch-users-goals');
        this.$refs.userGoalsFormValidator.reset();
        this.isLoading = false;
      }
    },

    async updateGoal() {
      this.$emit('update-goal');
    },
  },

};
</script>

<style scoped>
table {
  table-layout: fixed;
  width: 100%;
}

.requiredField:after {
  color: #d22222;
  content: '*';
  display: inline;
}

.distribute_field .block label:first-child {
  margin-right: 2.5em;
}

.period_breakdown_card {
  background-color: #ffffff;
  padding: 2em;
  max-height: 350px;
  display: flex;
  flex-direction: column;
}

.pb_list {
  overflow: auto;
  flex: 1;
  padding-right: 10px;
}

</style>
