<template>
  <div>
    <!-- page header component -->
    <page-header
      :name="organization.name"
      icon="sitemap"
    >
      <template #actions>
        <div class="level-item">
          <search-bar />
        </div>

        <div class="level-item">
          <b-dropdown aria-role="list">
            <b-button
              slot="trigger"
              type="is-info"
              icon-left="plus"
              icon-right="chevron-down"
            >
              create
            </b-button>
            <template v-if="hasPermissions('create_users')">
              <b-dropdown-item
                aria-role="listitem"
                class="has-text-grey"
                @click="$router.push('organization/add-user')"
              >
                User
              </b-dropdown-item>
              <hr class="dropdown-divider">
            </template>

            <template v-if="hasPermissions('create_accounts')">
              <b-dropdown-item
                aria-role="listitem"
                class="has-text-grey"
                @click="$router.push('/accounts/add')"
              >
                Account
              </b-dropdown-item>
              <hr class="dropdown-divider">
            </template>

            <template v-if="hasPermissions('create_contacts')">
              <b-dropdown-item
                aria-role="listitem"
                class="has-text-grey"
                @click="$router.push('/contacts/add')"
              >
                Contact
              </b-dropdown-item>
              <hr class="dropdown-divider">
            </template>

            <template v-if="hasPermissions('create_leads')">
              <b-dropdown-item
                aria-role="listitem"
                class="has-text-grey"
                @click="$router.push('/leads/add')"
              >
                Lead
              </b-dropdown-item>
              <hr class="dropdown-divider">
            </template>

            <template v-if="hasPermissions('create_leads')">
              <b-dropdown-item
                aria-role="listitem"
                class="has-text-grey"
                @click="$router.push('/opportunities/add')"
              >
                Opportunity
              </b-dropdown-item>
              <hr class="dropdown-divider">
            </template>

            <b-dropdown-item
              v-if="hasPermissions('create_activities')"
              has-link
              aria-role="menuitem"
              class="has-text-grey"
            >
              <router-link to="/activities/add">
                Activity
              </router-link>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </page-header>

    <!-- organization tabs component -->
    <section class="section">
      <div class="container">
        <b-breadcrumb size="is-small">
          <b-breadcrumb-item
            tag="router-link"
            to="/profile"
          >
            Home
          </b-breadcrumb-item>
          <b-breadcrumb-item
            tag="router-link"
            to="/organization"
            active
          >
            Organization
          </b-breadcrumb-item>
        </b-breadcrumb>

        <b-tabs v-model="activeTab">
          <b-tab-item label="Overview">
            <organization-overview
              :is-loading="isLoading"
              :organization="organization"
            />

            <drilldowns
              :active-accounts-count="stats.activeAccountsCount"
              :contacts-count="stats.contactsCount"
              :leads-count="stats.leadsCount"
              :opportunities-count="stats.opportunitiesCount"
              :open-activities-count="stats.openActivitiesCount"
            />

            <div class="level">
              <div class="level-left">
                <div class="level-item">
                  <div class="content">
                    <router-link to="/accounts">
                      <h2 class="subtitle has-text-info">
                        Recent Accounts
                      </h2>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>

            <div class="columns is-multiline">
              <div
                v-for="(account, index) in stats.recentAccounts"
                :key="index"
                class="column is-half"
              >
                <favourite-accounts-card :account="account" />
              </div>
            </div>
          </b-tab-item>

          <b-tab-item
            v-if="hasPermissions('read_users')"
            label="Users"
          >
            <users-table
              @assign-role="assignRole"
            />
          </b-tab-item>

          <b-tab-item label="Teams">
            <div
              v-if="hasPermissions('create_teams')"
              class="content"
            >
              <b-button
                type="is-info"
                icon-left="plus"
                @click="isTeamsFormModalActive = true"
              >
                Add New Team
              </b-button>
            </div>

            <div class="columns is-multiline">
              <div
                v-for="(team, index) in teams"
                :key="index"
                class="column is-one-third"
              >
                <teams-card
                  :team="team"
                  @edit-team="editTeam"
                  @delete-team="deleteTeam"
                  @add-team-member="addTeamMember"
                  @delete-team-member="deleteTeamMember"
                />
              </div>
            </div>

            <hr>

            <b-pagination
              v-model="currentTeamsPage"
              :total="teamsTotal"
              :per-page="perPage"
              aria-next-label="Next page"
              aria-previous-label="Previous page"
              aria-page-label="Page"
              aria-current-label="Current page"
              @change="onTeamsPageChange"
            />

            <b-modal
              v-model="isTeamsFormModalActive"
              scroll="keep"
              :width="400"
            >
              <teams-form
                @close="isTeamsFormModalActive = false"
                @save-team="isEditingTeam ? updateTeam() : createTeam()"
              />
            </b-modal>
          </b-tab-item>

          <b-tab-item label="Divisions">
            <div
              v-if="hasPermissions('create_teams')"
              class="content"
            >
              <b-button
                type="is-info"
                icon-left="plus"
                @click="isDivisionsFormModalActive = true"
              >
                Add New Division
              </b-button>
            </div>

            <div class="columns is-multiline">
              <div
                v-for="(division, index) in divisions"
                :key="index"
                class="column is-one-third"
              >
                <divisions-card
                  :division="division"
                  @edit-division="editDivision"
                  @delete-division="deleteDivision"
                  @add-division-member="addDivisionMember"
                  @delete-division-member="deleteDivisionMember"
                />
              </div>
            </div>

            <hr>

            <b-pagination
              v-model="currentDivisionsPage"
              :total="divisionsTotal"
              :per-page="perPage"
              aria-next-label="Next page"
              aria-previous-label="Previous page"
              aria-page-label="Page"
              aria-current-label="Current page"
              @change="onDivisionsPageChange"
            />

            <b-modal
              v-model="isDivisionsFormModalActive"
              scroll="keep"
              :width="400"
            >
              <divisions-form
                @close="isDivisionsFormModalActive = false"
                @save-division="isEditingDivision ? updateDivision() : createDivision()"
              />
            </b-modal>
          </b-tab-item>
        </b-tabs>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import OrganizationMixin from '@/mixins/OrganizationMixin';
import TeamsMixin from '@/mixins/TeamsMixin';
import DivisionsMixin from '@/mixins/DivisionsMixin';

import { Drilldowns, FavouriteAccountsCard, PageHeader } from '@/components/Shared';
import {
  OrganizationOverview,
  UsersTable,
  DivisionsCard,
  DivisionsForm,
  TeamsCard,
  TeamsForm,
} from '@/components/Organization';
import { SearchBar } from '@/components/Search';

export default {
  name: 'ViewOrganization',

  components: {
    Drilldowns,
    FavouriteAccountsCard,
    PageHeader,
    OrganizationOverview,
    UsersTable,
    DivisionsCard,
    DivisionsForm,
    TeamsCard,
    TeamsForm,
    SearchBar,
  },

  mixins: [OrganizationMixin, TeamsMixin, DivisionsMixin],

  data: () => ({
    activeTab: 0,
  }),

  computed: mapGetters({
    role: 'Authentication/getRole',
  }),

  mounted() {
    this.setTab(parseInt(this.$route.query.activeTab, 10));
  },

  methods: {
    setTab(value) {
      this.activeTab = value;
    },

    /**
     * assignRole
     *
     * assign a role to a user
     *
     * @params {Object} payload
     */
    async assignRole(payload) {
      try {
        await this.$store.dispatch('Security/assignRole', payload);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style></style>
